import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import nuvem from '../../../src/assets/nuvem.webp';
import db from '../../../src/assets/db.webp';
import lab_2 from '../../../src/assets/lab_2.webp';
import usina_de_energia_solar from '../../../src/assets/usina_de_energia_solar.webp';
import clean from '../../../src/assets/clean.webp';
import inst_home from '../../../src/assets/sist_foto.webp';
import manag_dashb from '../../../src/assets/manag_dashb.webp';
import viab_projec from '../../../src/assets/viab_projec.webp';
import panel_eletric from '../../../src/assets/panel_eletric.webp';
import v_voltmais from '../../../src/assets/v_voltmais.webp';
import man_prev_corr from '../../../src/assets/man_prev_corr.webp';
import WhatsAppButton from '../../../src/components/WhatsAppButton/WhatsAppButton';
import FadeInSection from '../../../src/components/FadeInSection/FadeInSection';

const solutionsData = [
  { text: "Estudo de viabilidade".toUpperCase(), image: viab_projec },
  { text: "Implantação de projetos elétricos. Painéis, armários, Quadro Geral de Baixa Tensão e afins.".toUpperCase(), image: panel_eletric },
  { text: "Manutenção preventiva ou corretiva".toUpperCase(), image: man_prev_corr },
  { text: "Montagem industrial para grandes e pequenas empresas".toUpperCase(), image: lab_2 },
  { text: "Montagem de data center (CPD e cabeamento estruturado)".toUpperCase(), image: db },
  { text: "Projeto de usina fotovoltaica".toUpperCase(), image: usina_de_energia_solar },
  { text: "Instalação de sistema fotovoltaico".toUpperCase(), image: inst_home },
  { text: "Gestão de usina de energia limpa fotovoltaica".toUpperCase(), image: manag_dashb },
  { text: "Limpeza de módulos PV".toUpperCase(), image: clean },
];

const Solutions = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Função para enviar uma mensagem via WhatsApp com o texto da solução
  const handleWhatsAppMessage = (solutionText: string) => {
    const phoneNumber = "5551998356981";
    const message = `Olá! Estou interessado em saber mais sobre o serviço "${solutionText}" oferecido pela VOLTMAIS.`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-gradient-to-b from-[#0055fe] to-[#003399] overflow-x-hidden">
      <Navbar />
      <div className="flex-grow flex flex-col justify-center items-start p-6 md:p-12 bg-opacity-80 bg-[#034cdf81] mx-[2.5%] mt-[125px] rounded-t-3xl z-40 shadow-2xl">
        <FadeInSection>
          <h1 className="text-7xl md:text-9xl font-bold text-start my-12 ml-0 md:ml-60 AcuminPro-font">
            <span className="text-[#4ed44f]">Serviços</span> <br />
            <span className="text-white">Realizados</span> <br />
            <span className="text-white font-extrabold italic">VOLTMAIS</span>
          </h1>
        </FadeInSection>

        <div className="w-[80%] h-[1px] bg-gradient-to-r from-[#4ed44f] via-[#90ff3c] to-[#4ed44f] my-16 mx-auto rounded-full"></div>

        <div className="w-full my-12">
          {solutionsData.map((solution, index) => (
            <FadeInSection key={index}>
              <div
                className="w-full group relative overflow-hidden rounded-3xl shadow-lg transition-all duration-300 ease-in-out hover:shadow-2xl hover:-translate-y-2 cursor-pointer my-8"
                onClick={() => handleWhatsAppMessage(solution.text)}
              >
                <img
                  src={solution.image}
                  alt={solution.text}
                  className={`w-full h-80 object-cover transition-transform duration-500 ease-in-out group-hover:scale-110 ${
                    solution.text === "PROJETO DE USINA FOTOVOLTAICA" ? "md:object-bottom" : ""
                  }`}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-80 transition-opacity duration-300"></div>
                <div className="absolute bottom-0 left-0 right-0 p-6 bg-black bg-opacity-20">
                <h3 className="text-white text-xl font-bold mb-2">{solution.text}</h3>
                <p className="text-gray-300 text-sm">Clique para saber mais</p>
                </div>
              </div>
            </FadeInSection>
          ))}
        </div>
      </div>
      <img
        src={nuvem}
        alt="Nuvem 1"
        className="absolute w-[800px] h-auto z-0"
        loading="lazy"
        style={{ top: '400px', left: '-8%' }}
      />
      <img
        src={v_voltmais}
        alt="Nuvem 2"
        className="absolute w-[800px] h-auto z-0"
        loading="lazy"
        style={{ top: '-1vh', right: '-15vh' }}
      />
      <img
        src={nuvem}
        alt="Nuvem 3"
        className="absolute w-[800px] h-auto z-0"
        loading="lazy"
        style={{ top: '1000px', left: '65%' }}
      />

      <WhatsAppButton />
      <Footer />
    </div>
  );
};

export default Solutions;
