import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Logo from "../../../src/assets/voltmais_completo.webp";
import { Link } from 'react-router-dom'

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const closeNav = () => {
    setNav(false);
  };

  return (
    <div className="fixed w-full flex justify-between items-center px-5 py-5 z-50 bg-[#0055fe]">
      <Link to="/" onClick={closeNav}>
        <img className="w-[150px] cursor-pointer" src={Logo} alt="Logo Voltmais" />
      </Link>
      <div onClick={handleNav} className="block cursor-pointer">
        {nav ? <AiOutlineClose size={20} color="#fff" /> : <AiOutlineMenu size={20} color="#fff" />}
      </div>
      <div
        className={`${nav ? "left-0" : "left-[-100%]"
          } fixed top-0 w-[300px] h-full text-white transition-all duration-500 z-40 bg-[#0055fe]`}
      >
        <img className="w-[150px] mx-auto my-5" src={Logo} alt="Imagem Espaço"/>
        <ul className="uppercase p-4">
        <li className="p-4 border-b border-white" onClick={closeNav}>
            <Link className='cursor-pointer z-1000 roboto-flex-font' to="/" onClick={closeNav}>
              INÍCIO
            </Link>
          </li>
          <li className="p-4 border-b border-white" onClick={closeNav}>
          <Link className='cursor-pointer z-1000 roboto-flex-font' to="/aboutUs" state={{ scrollToTop: true }} onClick={closeNav}>
              SOBRE NÓS
            </Link>
          </li>
          <li className="p-4 border-b border-white" onClick={closeNav}>
          <Link className='cursor-pointer z-1000 roboto-flex-font' to="/solutions" state={{ scrollToTop: true }} onClick={closeNav}>
              SOLUÇÕES
            </Link>
          </li>
          <li className="p-4" onClick={closeNav}>
          <Link className='cursor-pointer z-1000 roboto-flex-font' to="/contact" state={{ scrollToTop: true }} onClick={closeNav}>
              CONTATO
          </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
