import React, { useState, useEffect } from "react";

const testimonials = [
  { author: "Gabriel Pinheiro", text: "Excelente atendimento e módulos de alta qualidade!" },
  { author: "Alex Faleiro", text: "Empresa séria, expert no assunto, comprometida com seus clientes e com o meio ambiente. Além de trabalhar apenas com equipamentos de 1° linha a nível mundial, possui um atendimento excelente e contam ainda com uma equipe super qualificada." },
  { author: "Amanda Machado", text: "Excelente atendimento e grande comprometimento dos profissionais. A empresa Voltmais nos deixou extremamente satisfeitos com a qualidade entregue e todo o suporte para avaliar os resultados já visíveis no mês da instalação. Se estiverem pesquisando uma empresa de energia solar, recomendo muito! O atendimento do profissional Júlio foi sem igual, nossa gratidão pela atenção! Estendendo o agradecimento a equipe de instalação também. Parabéns pela atenção com os clientes!" },
  { author: "Carlos Alberto Filippin", text: "Excelente profissionalismo, empresa de alta confiabilidade no serviço prestado. As referências são as melhores, tanto de clientes como profissionais do segmento pelo respeito e conhecimento adquirido. Profissional com mais de 16 anos de mercado, com formação em energia fotovoltaica na Alemanha, pós-venda de altíssima qualidade. Vale a pena ter um parceiro desta capacidade técnica para auxiliar no investimento em Energia Solar Fotovoltaica." },
  { author: "Diego Giacomelli", text: "Serviço de avaliação/analise e execução de excelência! Empresa transparente e de fato preocupada em entregar serviço de qualidade e acompanhamento. Super recomendo!" },
  { author: "Ricardo Gonzalez", text: "Conto com serviços técnicos e atendimento da VoltMais desde 2015 e o atendimento do Júlio Muller é o diferencial no mercado, pontual e comprometido com o cliente a frente da VoltMais desenvolve os projetos com rapidez e destreza, estou muito satisfeito, eu recomendo." },
  { author: "Dario Silva", text: "Muito bom o atendimento, a segurança na demonstração do produto e do projeto gera uma confiança de que se precisa para realizar um investimento deste porte, tendo a certeza do retorno esperado." },
  { author: "Armando Silva Neto", text: "Fiz o meu sistema de energia com a Voltmais, saiu tudo conforme combinado. Eles possuem uma assistência ao cliente muito boa, recomendo! O sistema está funcionando perfeitamente. Estou com excesso de energia inclusive." },
  { author: "Cristiane Tietze", text: "Postura profissional e sempre na busca pela excelência fazem da VoltMais a garantia da solução. Eu indico!" },
  { author: "Alexia Marchetto de Souza", text: "Empresa extremamente competente e equipe com ótimo conhecimento técnico!" },
  { author: "Maurício Pereira", text: "Ótimo custo/benefício e com um atendimento de primeira. Recomendo." },
  { author: "Janice Mosmann", text: "Acho muito bom utilizarmos a energia solar. Energia limpa. Contribuição positiva ao nosso meio ambiente." },
  { author: "Jose Henrique Hoeschl Mendonça", text: "Parceiro de longa data, projetos de primeira linha, super recomendo." },
  { author: "Moises Pereira", text: "Excelentes profissionais, eu recomendo!" },
  { author: "Leonardo Knuppe", text: "Ótimo serviço. Qualidade inigualável." },
  { author: "Paulo C Kuhn", text: "Serviço com altíssimo nível de qualidade." },
  { author: "Rômulo Wickert", text: "Excepcional!" },
];

const TestimonialsCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000); // Troca a cada 5 segundos

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, []);

  return (
    <div className="relative mx-4 sm:mx-24">
      {/* Testimonial Card */}
      <div className="relative justify-center min-h-96 sm:min-h-64 items-center">
        <div className="bg-white bg-opacity-10 border border-opacity-20 border-white rounded-2xl shadow-md text-[4vw] sm:text-[1.4vw] lg:text-[1.2vw] leading-tight text-white nirmala-font p-6 lg:p-8 flex-1">
          <p className="italic">"{testimonials[currentIndex].text}"</p>
          <footer className="mt-4 text-[#4ed44f] italic font-semibold">
            — {testimonials[currentIndex].author}
          </footer>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
